<template>
    <vFormPage :space="true">
        <div slot="header" class="header">
            <div class="title">MY PASSES</div>
            <vSearchBar v-model.trim="searchText" placeHolder="Search my passes" @input="fuzzySearch" />
        </div>
        <div class="body">
            <div v-if="is_loading" class="loading"> loading... </div>
            <vCardList :list="searchList" @itemClick="cardItemClick"></vCardList>
        </div>
    </vFormPage>
</template>
<style scoped>
    /* 顶部样式 */
    .title {
        color: #fff;
        font-size: 20px;
        padding: 15px 35px 0 35px;
        line-height: 35px;
        text-align: left;
    }

    .loading {
        font-size: 16px;
        margin-top: 100px;
        text-align: center;
    }

    /* 底部样式 */

    /* 表单样式 */
</style>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
    import vFormPage from '../../components/Formpage.vue';
    import vSearchBar from '../../components/SearchBar.vue';
    import vCardList from '../../components/CardList.vue';

    export default {
        name: 'v-pass-list',
        components: { vFormPage, vSearchBar, vCardList },
        data() {
            return {
                cardList: [],
                searchText: undefined,
                searchList: [],
            }
        },
        created() {
            this.initCardList({
                data: { cid: this.user.id }, success: (res) => {
                    this.cardList = res.list;
                    this.searchList = this.cardList;
                }
            });
        },
        mounted() {

        },
        computed: {
            ...mapGetters({
                is_loading: 'app/getLoading',
                user: 'app/getUser',
            })
        },
        methods: {
            ...mapActions({
                initCardList: 'app/initCardList',
            }),
            fuzzySearch(text) {
                if (!text || text === '') {
                    this.searchList = this.cardList;
                    return;
                }
                if (Array.isArray(this.cardList) && this.cardList.length > 0) {
                    this.searchList = this.cardList.filter(i => i.sname && i.sname.toLowerCase().indexOf(text) !== -1);
                }
            },
            cardItemClick(item) {
                this.$router.push({
                    name: '/pass/detail',
                    query: { sid: item.sid, cid: item.cid },
                });
            }
        }
    };
</script>