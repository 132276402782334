<template>
    <div class="search-wrapper">
        <img :src="SEARCH_SRC" />
        <input class="input-normal" :placeholder="placeHolder" ref="input" :type="type" :value="currentValue"
            @input="handleInput" @blur="handleBlur" />
    </div>


</template>
<style>
    .search-wrapper {
        width: 302px;
        height: 36px;
        border-radius: 24px;
        font-size: 16px;
        text-align: center;
        line-height: 48px;
        overflow: hidden;
        display: flex;
        background-color: #fff;
        margin:5px 36px;
    }

    .search-wrapper img {
        width: 0.14rem;
        height: 0.14rem;
        margin: 10px 5px 10px 20px;
    }

    .search-wrapper input.input-normal {
        height: 38px;
        font-size: 12px;
        outline-style: none;
        border: 0;
        border-bottom: 1px solid #ccc;
        border-radius: 3px;
        padding: 0 4px;
        border: none;
        outline: none;
    }
</style>
<script>
    import { SEARCH_SRC } from '../consts/const.js';
    export default {
        name: 'searchbar',
        props: {
            type: { type: String, default: 'text' },
            value: { default: '' },
            placeHolder: { type: String, default: '' }
        },
        watch: {
            value(value) {
                this.currentValue = value
            }
        },
        data() {
            return { currentValue: this.value, id: this.label, SEARCH_SRC }
        },
        mounted() {
            if (this.$parent.labelFor) this.$refs.input.id = this.$parent.labelFor;
        },
        methods: {
            handleInput(e) {
                const value = e.target.value;
                this.currentValue = value;
                this.$emit('input', value);
            },
            handleBlur() {
            }
        }
    }
</script>