<template>
    <div class="card-list-container">
        <div v-for="(item, i) in list" :key="i" class="item-wrapper" @click.prevent="handleItemClick(item, i)">
            <div class="header-wrapper" :style="{backgroundImage: 'url('+ item.spic +')', backgroundRepeat:'no-repeat',
            backgroundSize:'100% 100%' }"></div>
            <img class="center-wrapper-logo" alt="logo" :src="item.slogo">
            <div class="center-wrapper-name">{{item.sname}}</div>
            <div class="center-wrapper-info">{{item.sdesc}}</div>
        </div>
    </div>
</template>
<style scoped>
    .card-list-container {
        overflow-x: hidden;
        overflow-y: auto;
        font-size: 16px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .item-wrapper {
        border-radius: 12px;
        width: 47%;
        height: 260px;
        margin-bottom: 20px;
        background-color: #fff;
    }

    .item-wrapper:nth-child(2n-1) {
        margin-right: 6%;
    }

    .item-wrapper .header-wrapper {
        height: 160px;
        border-radius: 12px;
        background-color: aquamarine;
    }

    .item-wrapper .center-wrapper-logo {
        position: absolute;
        margin-left: 55px;
        margin-top: -25px;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        font-size: 0.14rem;
        background-color: #eee;
        object-fit: cover;
    }

    .item-wrapper .center-wrapper-name {
        position: absolute;
        margin-top: 37px;
        margin-left: 29px;
        width: 110px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-size: 14px;
        font-weight: bolder;
        line-height: 17px;
        text-align: -webkit-center;
    }

    .item-wrapper .center-wrapper-info {
        position: absolute;
        margin-top: 70px;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-size: 10px;
        opacity: .8;
        text-align: -webkit-center;
        width: 165px;
    }
</style>

<script>
    import Vue from 'vue';
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';

    export default {
        name: 'v-card-list',
        props: {
            list: { type: Array, default: () => [{ sname: '', spic: '', sdes: '', slogo: '' }] },
            defaultActive: { type: Object, default: () => ({}) }
        },
        data() {
            return {
                activeItem: this.defaultActive
            }
        },
        created() {
        },
        mounted() {

        },
        computed: {
        },
        methods: {
            handleItemClick(item, i) {
                this.activeItem = item;
                this.$emit('itemClick', item);
            }
        }
    };
</script>